@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap');
@import url('https://cdn.jsdelivr.net/gh/eliyantosarage/font-awesome-pro@main/fontawesome-pro-6.5.1-web/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
body {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}
.wrapper-class {
  background: #fff !important;
  border-style: none;
  padding: 0px;
}
.editor-class {
  background: #fff !important;
  border-style: none;
  padding: 5px;
  border: 0.5px solid #eee;
  border-radius: 5px;
}
.toolbar-class {
  border-style: none;
  background: transparent !important;
}
.toolbar-class .rdw-option-wrapper {
  background: #fff !important;
  box-shadow: 0px 0px 3px #ddd;
  border-radius: 5px;
  border-style: none;
}
.rdw-option-active {
  box-shadow: 0.5px 0.5px 2px #bfbdbd inset !important;
}

/* .rs-picker-daterange-panel .rs-stack .rs-stack-item:nth-child(1) .rs-picker-daterange-header{
  display: none;
} */
.rs-anim-fade{
  z-index: 999999;
}
.rs-picker-daterange-panel
  .rs-stack
  .rs-stack-item:nth-child(1)
  .rs-picker-toolbar
  .rs-stack-item:nth-child(1) {
  display: none;
 
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  display: none;
}

.leaflet-left .leaflet-control {
  margin-left: 330px;
}

.leaflet-marker-icon.leaflet-interactive{
  width:10px;
  height:10px;
}
.leaflet-popup-content {
  margin: 0px;
}
.leaflet-routing-container.leaflet-bar.leaflet-control {
  display: none;
}
.leaflet-popup-content-wrapper{
  min-width: 330px;
}
.leaflet-container a.leaflet-popup-close-button {
  background-color: #f32130; 
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  opacity: 0.8;
}
.leaflet-popup-tip-container {
  height:57px
}
.leaflet-popup-tip {
  width: 40px;
  height: 70px;
  padding: 1px;
  margin: -14px auto 0;
  pointer-events: auto;
  transform: rotate(0deg); 
  clip-path: polygon(50% 100%, 0% 0, 100% 0);
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  margin-bottom: 60px;
}
.MuiAccordionSummary-content,
.MuiAccordion-root {
  font-family: inherit !important;
}
.rs-calendar-table-cell{
  color: rgba(0, 0, 0, 0.87) !important;
}
.rs-calendar-table-cell-disabled{
  color: #c5c6c7 !important;
}

.gm-style .gm-style-iw-c{
  background: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
  max-width: '320px !important';
}
.gm-style .gm-style-iw-d{
  overflow:hidden !important;
}
.gm-ui-hover-effect>span{
  background-color:#fff;
}
.gm-style .gm-style-iw-tc::after{
  background: #fff!important;
  height: 60px;
  width: 20px;
}

.iframe{
  border-style: none;
}
.iframe .toolbarTableBG{
  position: absolute !important;
    width: fit-content !important;
    height: fit-content !important;
    z-index: 99999 !important;
    right: 0px !important;
}

/*.sidebarScroll::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* .sidebarScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
/* .sidebarScroll::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
}  */

/* Bootstrap Datepicker */
/* div[title="animated-marker"] img{
  transition: all .25s ease;
  transform: rotate(45deg);
} */

.labels {
  color: black;
  background-color: white;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  width: 6em;
  border: 1px solid black;
  white-space: normal;
}

div[title="animated-marker"] {
  transition: all .85s;
}
div[title="animated-marker"] img{
  opacity: 1 !important;
  transition: all .85s;
}

div[title="dashcam"]{
  width: 50px;
  height: 50px;
  overflow: hidden;
  background: red
}

div[title="alertName"]{
  position: relative;
  border-radius: 50%;
}
div[title="alertName"]::before, div[title="alertName"]::after{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  animation: growAndFade 3s infinite ease-out;
  border-radius: 50%;
  opacity: 0;
  border:.5px solid #888
}
div[title="alertName"]::before{
  animation-delay: 1s;
}
div[title="alertName"]::after{
  animation-delay: 2s;
}

@keyframes growAndFade {
  0% {
    opacity: .5;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}


.canvasjs-chart-credit{
  display: none;
}

/* div[title="animated-marker"]::before{
  width: 30px;
  height: 30px;
  background-image: linear-gradient(133deg, #000000 -5%, transparent 57%);
  content: "";
  position: absolute;
  left: 9px;
  top: 8px;
  z-index: -1;
  clip-path: polygon(100% 48%, 0 100%, 0 1%);
} */

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}


.gm-ui-hover-effect{
  background: #ff0000 !important;
  border-radius: 50px !important;
  width: 26px !important;
  height: 26px !important;
  position: absolute !important;
  top: 18px !important;
  right: 0px !important;
  z-index: 9999;
}

.gm-ui-hover-effect span{
    width: 16px !important;
    height: 16px !important;
    margin: 5px !important;

}
.ui-video-seek-slider .hover-time{
  background: #28282b;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 12px;
  font-size: 12px;
}

.ui-video-seek-slider .track .main .connect{
  background:  green !important;
}

.ui-video-seek-slider .track .main:first-child .connect{
  background: #28282b !important;
}
.ui-video-seek-slider .track .main:last-child .connect{
  background: #c21d6d !important;
}
div[title="animated-marker"] {
	transition: all .25s ease !important
}

.scheduler-container {
  height: 100vh;
  width: 100vw;
}

.fc-license-message{
  display: none;
}

.loader {
  width: 45px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#1a2e7a 90%,#f2f7fa);
  background: 
  var(--_g) 0%   50%,
  var(--_g) 50%  50%,
  var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20%{background-position:0%   0%, 50%  50%,100%  50%}
  40%{background-position:0% 100%, 50%   0%,100%  50%}
  60%{background-position:0%  50%, 50% 100%,100%   0%}
  80%{background-position:0%  50%, 50%  50%,100% 100%}
}